import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import router from '@/router';
export default {
  name: 'AssetsCheckSuccess',
  mixins: [common],
  data() {
    return {};
  },
  methods: {
    onNext() {
      this.goBack(-2);
      setTimeout(() => {
        router.push({
          name: 'AssetsCheckSelect'
        });
      }, 500);
    }
  }
};